import React from 'react';
import {Card} from 'react-bootstrap';
import Layout from '../components/layout'

function Person({name, position, image}) {

    return (<div className="col-12 col-md-4 col-sm-6 mb-3">
        <Card>
            <Card.Img variant="top" src={image} />
            <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>{position}</Card.Text>
            </Card.Body>
        </Card>
    </div>)
}

export default function About(props) {
    const data = {
        team: [
            {
                name: "Austin Riley",
                position: "Software Engineer",
                image: '/team/Austin.png',
            },
            {
                name: "Chris Thibault",
                position: "Software Engineer",
                image: '/team/Chris.png',
            },
            {
                name: "Drew Heavner",
                position: "Software Engineer",
                image: '/team/Drew.png',
            },
            {
                name: "Evan Owen",
                position: "Software Engineer",
                image: '/team/Evan.png',
            },
            {
                name: "Kaitlin McKnight",
                position: "Project Manager",
                image: '/team/Kaitlin.png',
            }
        ]
    }

    return (
    <Layout>
        <article className="py-5">
            <header className="container text-white">
                <h1>About Us</h1>
                <p>We are a small team of developers and designers with a passion for the environment – trying to make an impact from the ground up. We decided to put our app development skills to work to support what we believe in and built Greener with the mission to empower consumers and small businesses with the tools they need to choose clean energy.</p>
            </header>
            <div className="container team">
                <div className="row">
                    { data.team.map(({name,position,image},index) => <Person key={index} name={name} position={position} image={image} />) }
                </div>
            </div>
        </article>
    </Layout>)
}

